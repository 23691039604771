import React from "react"
import styled from "styled-components"
import { styles } from "../../utils"

const Dish = ({ align = `left`, img, name, pos = `50% 50%`, posMd }) => (
  <ShowcasedDish alignment={align} loading="lazy">
    <header>{name}</header>
    <img
      alt={name}
      loading="lazy"
      src={img}
      style={{ "--obj-p": pos, "--obj-p-md": posMd }}
    />
  </ShowcasedDish>
)

const ShowcasedDish = styled.article`
  --bg: white;

  background: var(--bg);
  height: 100%;
  isolation: isolate;
  position: relative;
  width: 100%;

  header {
    background: var(--bg);
    bottom: 0;
    color: ${styles.colors.mainBlack};
    font-weight: 700;
    padding: 0.5rem var(--gap, 0.5rem);
    position: absolute;
    text-transform: uppercase;
    z-index: 10;
    ${props => props.alignment}: 0;
  }

  img {
    height: 100%;
    inset: 0;
    object-fit: cover;
    object-position: var(--obj-p, 50% 50%);
    position: absolute;
    width: 100%;
    z-index: 0;

    @media (min-width: 768px) {
      object-position: var(--obj-p-md, var(--obj-p));
    }
  }
`

export default Dish
