import React from "react"
import styled from "styled-components"
import { styles } from "../../utils"
import Dish from "../globals/Dish"
import shrimp from "../../images/gallery/azalea-shrimp-grits.jpg"
import biscuit from "../../images/gallery/biscuit-close-up-4.jpg"
import burger from "../../images/gallery/burger-beer-vert.jpg"

const DishShowcase = () => {
  const dishes = [
    {
      img: shrimp,
      name: `Shrimp + Grits`,
      pos: `50% 60%`,
    },
    {
      img: biscuit,
      name: `Blood Orange Biscuit & Cream`,
      pos: `50% 35%`,
      posMd: `50% 25%`,
    },
    {
      align: `right`,
      img: burger,
      name: `Shogun Burger`,
      pos: `50% 50%`,
    },
  ]

  return (
    <Wrapper>
      <Dish {...dishes[0]} />
      <ContentWrapper>
        <Content>
          <p>
            <Highlight>Azalea Bar & Kitchen</Highlight> evokes a sophisticated
            yet chilled vibe meant to inspire and awaken the senses. Enjoy a
            chef-driven menu that fuses classic fare with elevated techniques,
            local beverages, curated cocktails, and house-made desserts that
            celebrate the tastes of the South.
          </p>
        </Content>
      </ContentWrapper>
      <Dish {...dishes[1]} />
      <Dish {...dishes[2]} />
    </Wrapper>
  )
}

const Wrapper = styled.section`
  --gap: 1rem;
  --height: 200vh;
  --temp: repeat(4, 1fr) / 1fr;

  display: grid;
  gap: var(--gap);
  grid-template: var(--temp);
  height: var(--height);
  margin: auto;
  max-width: 100vh;
  padding: var(--gap);

  @media (min-width: 768px) {
    --gap: 1rem;
    --height: 100vh;
    --temp: 1fr 1fr / 1fr 1fr;
  }
`

const ContentWrapper = styled.div`
  background: ${styles.colors.primaryColor};
  padding: var(--gap);
`

const Content = styled.article`
  border: 2px solid ${styles.colors.secondaryColor};
  color: ${styles.colors.mainWhite};
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: var(--gap);
  height: 100%;
  padding: var(--gap);
  text-align: left;

  h3 {
    color: ${styles.colors.secondaryColor};
    font: ${styles.fontDecor({ size: `2.5rem` })};
    margin: 0 auto;
    max-width: 80%;
  }

  p {
    letter-spacing: 1px;
    margin: 0 auto;
    max-width: 90%;
    padding-top: var(--gap);
  }
`

const Highlight = styled.span`
  color: ${styles.colors.secondaryColor};
`

export default DishShowcase
